export default class Calendar {

  init(){

    $('.pickup-date').on('click', (e) => {
      e.preventDefault();
      let link = e.target.closest('a.pickup-date');
      let pickupDate = $(link).data('pickup-date')
      console.log(pickupDate)

      let isClosed = pickupDate['closed?'];
      let closedText = (isClosed) ? '<br /><br /><span style="color: #a81e27;">This pickup date is currently closed</span>' : '';
      $(".modal-title").html("<h4>" + pickupDate.title + "</h4>");
      $(".modal-body").html("<h4> Location: " + pickupDate.location + "<br />" + "Start Time: " + pickupDate.start_time + " CST" + "<br />" + "End Time: " + pickupDate.end_time + " CST" + closedText + "</h4>");
      $(".modal-footer #pickup_date_id").val(pickupDate.id)
      $('#select-pickup-date').toggle(!isClosed);
      jQuery(".modal").modal({})

    })

  }

}
