// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs";
//import * as ActiveStorage from "@rails/activestorage"
//import "channels"
import jQuery from "jquery"
import "bootstrap"
//import Turbolinks from 'turbolinks';
import Calendar from './components/calendar'
import "../stylesheets/application";

Rails.start();
//Turbolinks.start();
//ActiveStorage.start()

// Hacky, but assign jQuery to the window object so it's global
window.jQuery = jQuery
window.$ = jQuery

jQuery(document).ready(function($){

  //Initialize the pickup date calendar we are on the calendar page
  if($('.calendar.pickup-dates').length > 0){
    let calendar = new Calendar();
    calendar.init();
  }

})
